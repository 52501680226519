:root {
  --color-primary: #dd1b35;
  --color-secondary: #002d47;
  --color-tertiary: #f1f5f5;
  --color-black: #000;
  --color-grey: #dadcdd;
  --color-white: #fff;
  --color-text: #111;
  --color-link: #dd1b35;
  --rgb-primary: 221, 27, 53;
  --rgb-secondary: 0, 45, 71;
  --rgb-tertiary: 241, 245, 245;
  --rgb-black: 0, 0, 0;
  --rgb-grey: 218, 220, 221;
  --rgb-white: 255, 255, 255;
  --hsl-grey-h: 200;
  --hsl-grey-s: 4.23%;
  --hsl-grey-l: 86.08%;
}

div#tingle-modal23 {
  width: 100%;
  height: 100%;
}

.tingle-modal--visible {
  width: 100%;
  height: 20%;
}

#nav:before {
  /*    background: #283e57;*/
  background: transparent !important;
  opacity: 0.5;
}

/*#nav__primary>li a.is-active, #nav__primary>li a:active, #nav__primary>li a:focus, #nav__primary>li.menu-item-has-children>a:hover {*/
#nav__primary>li a.is-active,
#nav__primary>li a:active,
#nav__primary>li.menu-item-has-children>a:hover {
  color: #ffffff;
}

#nav__primary>li a:active,
#nav__primary>li a:focus {
  /*    color: #002d47 ;*/
  padding-left: 20px !important;
}

#nav__primary>li.menu-item-has-children>.sub-menu>ul li a:hover {
  color: #002d47 !important;
}

#nav__primary>li a:hover {
  padding-left: 20px !important;
}

#nav__primary>li.menu-item-has-children>a {
  transition: all .40s;
}

#nav__primary>li.menu-item-has-children>a:hover {
  padding-left: 20px !important;
}

/*Transtion is for open & close on button*/
.opennav,
.closenav {
  transition: all .56s ease-in-out !important;
  /*    width: initial;*/
}

div#nav__wrapper {
  padding-top: 50px;
}

#nav__title .logo {
  margin-bottom: 50px;
}

#nav__wrapper {
  background: #283e57;
}

@media (min-width: 961px) {
  #nav__wrapper {
      width: 35%;
      float: right;
  }

  #nav__primary {
      float: left;
      /*    padding-right:0%;*/
      max-width: 100%;
  }

  #nav__primary>li.menu-item-has-children>.sub-menu {
      background: var(--color-white);
      color: var(--color-text);
      height: 100%;
      left: 50%;
  }

  #nav__primary>li.menu-item-has-children>.sub-menu>ul {
      width: 50%;
      overflow-y: scroll;
      top: -5em;
      height: 100vh;
      /* height: 22em; */
      /*    border: 1px sol;*/
  }
}
.grn:hover {
  color: green;
}

/* Footer CSS Start */
@keyframes moveInleft {
  0% {
      opacity: 0;
      transform: translateX(-100px);
  }
  80% {
      transform: translateX(10px);
  }
  100% {
      opacity: 1;
      transform: translate(0);
  }
}
/* Footer CSS End */

div[id^="err"] {
  font-weight: 800;
}

marquee img {
  width: 110px !important;
  height: auto;
}

.article--product-type h3 {
  font-weight: bold;
  color: #283e57;
  font-size: 16px;
}

article.article--product-type img {
  margin-bottom: 10px;
}

.article--product-type a:before {
  display: none;
}

/*select tag for Download your 2023 brochure*/
select {
  background: hsl(var(--hsl-grey-h), var(--hsl-grey-s), calc(var(--hsl-grey-l) + 10%));
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 10px 20px;
  color: var(--color-black);
}
.displaynone {
  display: none;
}
.titleborder {
  color: #dd1b35;
  border-bottom: 1px solid black;
  text-transform: capitalize;
}