.bg-slider-explores {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* bottom: 100px; */
    z-index: -1;
    background-image: url(https://www.middlebycelfrost.com/allapi/assets/img/explore1.jpg);
}
.servicepageinput {
    background: #ffffff;
    border: 1px solid #cccccc;
    width: 100%;
    height: 60px;
    color: grey;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    padding: 20px 29px !important;
    /* padding-right: 20px; */
    border-radius: 7px;
    transition: all 500ms ease;
    font-family: 'poppins';
}

.contact-form form input[type="text"],
.contact-form form input[type="email"],
.contact-form form textarea {
    font-family: 'poppins';
}

/* input::placeholder,
select {
    color: grey ! important;
}

input {
    color: #333 !important;
} */
input.servicepageinput:focus-visible, input.servicepageinput:focus,
select.servicepageinput:focus-visible, select.servicepageinput:focus {
    outline: none;
}
