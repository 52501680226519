.bg-slider {
    bottom: 150px;
    z-index: -1;
    background-image: url("https://www.middlebycelfrost.com/allapi/assets/img/culinary.jpg");
}
.bg-img {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://www.middlebycelfrost.com/allapi/assets/img/bg-img.jpg");
    background-blend-mode: luminosity;
    height: 100%;
}
.bg-itm {
    padding: 250px 0;
}
.breadcrumbs ol.text-white li+li:before {
    filter: invert(1);
    background-image: url("../../public/assets/uploads/right-arrow-angle.png") 50% no-repeat;
    background-size: contain;
    content: "";
    display: block;
    height: 10px;
    left: 1px;
    margin-top: -5px;
    position: absolute;
    top: 50%;
    width: 10px;
}
.blogDesc p{
    display: inline;
}

.item:hover {
    background: #002d47;
    box-shadow: 0px 0px 15px 0px #000000da;
}
.item .item-shadow {
    position: relative;
    padding: 45px 30px;
    margin-bottom: 30px;
    background: #dd1b35;
    transition-duration: 1s;
}
 .item {
    border-radius: 12px;
    /* position: relative; */
    padding: 45px 30px;
    margin-bottom: 30px;
    background: #dd1b35;
    transition-duration: .5s;
    box-shadow: 0px 0px 15px 0px #000000da;
    
}
.item.h-320{
    box-shadow: 0px 0px 15px 0px #000000da;
    height: 320px;
} 
.item h5 {
    font-size: 24px;
    color: #fff;
    margin-bottom: 15px;
}
.item strong {
    color: #ffffff;
}
.item p {
    /* font-size: 13px; */
    color: #cccccc;
    margin: 0;
    line-height: 1.5em;
}
.item.item.h-320 p {
    font-size: 16px;
    color: #cccccc;
    margin: 0;
    line-height: 1.5em;
}
.wysiwyg a:not(.btn), a, a:hover {
    text-decoration: none;
}
.item .icon i {
    font-size: 45px;
}
.article--videos {
    height: 400px;
}
.article--videos .bottom{    
    min-height: 100px;
    background:white;
    color: black;
}
.article--videos .bottom .content h2{
    font-size: 25px;
}
.youtube-absolute{
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
}
.video-iframe{
    width: 100%;
    height: 70vh;
}