h1.sec-subtitle.fontsizelrg.text-white {
    font-size: 40px;
}

.article--product__top.h-250 {
    height: 250px;
}

img.aboutimage1.exp-img1 {
    position: relative;
    top: 50px;
    right: 50px;
    z-index: 1;
    width: 320px;
}

img.aboutimage1.exp-img2 {
    /* width: 300px; */
    position: relative;
    left: 65px;
}

img.aboutimage1.exp-img3 {
    position: relative;
    top: 60px;
    left: 150px;
    z-index: 1;
    width: 320px;
}

img.aboutimage1.exp-img4 {
    position: relative;
}

.bg-img-2 {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://www.middlebycelfrost.com/allapi/assets/img/bg-img-2.jpg");
    background-blend-mode: luminosity;
    height: 100%;
}

.bg-slider-explore {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* bottom: 100px; */
    z-index: -1;
    background-image: url("https://www.middlebycelfrost.com/allapi/assets/img/front.png");
}

.bg-itm2 {
    /* padding: 250px 0; */
    z-index: 2;
}

.elementor-background-overlay {
    z-index: 1;
    background-color: transparent;
    background-image: linear-gradient(180deg, #000000 0%, #00000000 100%);
    opacity: 0.8;
    transition: background 0.8s, border-radius 0.8s, opacity 0.5s;
}

.bg-primary {
    background-color: #dd1b35;
    color: white;
    width: max-content;
    padding: 0px 10px;
}

.elementor-background-overlay {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

@media (max-width: 1024px) {
    img.aboutimage1.exp-img3 {
        top: 50px;
        left: 60px;
    }

    img.aboutimage1.exp-img2 {
        left: 0;
    }
}

@media (max-width: 768px) {
    img.aboutimage1.exp-img2 {
        left: 0;
    }
}

@media (max-width: 767px) {
    img.aboutimage1.exp-img1 {
        top: 0;
        right: 0;
        width: 100%;
    }

    img.aboutimage1.exp-img3 {
        top: 0;
        left: 0;
        width: 100%;
    }

    img.aboutimage1.exp-img2,
    img.aboutimage1.exp-img4 {
        left: 0;
        width: 100%;
    }
}



/* deepak css   */


.bg-white {
    background-color: #fff !important;
}

.bg-black {
    background-color: #1e1e1e !important;
}


.bg-blue {
    background-color: #15314e !important;
}

.text-blue {
    color: #15314e;
}

.text-wheat {
    color: wheat !important;
}



.fade-in {
    opacity: 1;
    transition: opacity 2s ease-in-out;
}

.fade-out {
    opacity: 0;
    transition: opacity 2s ease-in-out;
}



/* Delayed fade-in animation */
.fade-in.delayed {
    animation: fadeInAnimation 2s ease-in-out 10s forwards;
}

@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade-in-right {
    animation: fade-in-right 2.5s ease forwards;
}

@keyframes fade-in-right {
    from {
        opacity: 0;
        transform: translateX(-50%);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}


.fade-in-up {
    animation: fade-in-up 2.5s ease forwards;
}

@keyframes fade-in-up {
    from {
        opacity: 0;
        transform: translateY(50px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Apply fade-in-right animation when visible */


/* CSS for changing background color to blue and text color to white on hover for all anchor tags */
/* Styles for anchor tags */

/* Styles for anchor tags within a specific section */
.section--products a {
    transition: all 1s;
}

/* .section--products a:hover {
    background-color: #15314e;
} */

/* a:hover h2,
a:hover p {
    color: white;
} */

.fw-bold {
    font-weight: 700;
}

.fw-6 {
    font-weight: 600;
}

.fw-normal {
    font-weight: 500;
}

.list-unstyled {
    list-style: none !important;
}

.list-unstyled li:before {
    background: none !important;
}

.fs-1 {
    font-size: 1em !important;
}

.fs-1-2 {
    font-size: 1.2em !important;
}

.fs-2 {
    font-size: 2em !important;
}

.fs-3 {
    font-size: 3em !important;
}

.fs-4 {
    font-size: 4em !important;
}

.lh-base {
    line-height: 1.4em;
}

.w-100 {
    width: 100% !important;
}

.h-80 {
    height: 80vh !important;
    width: -webkit-fill-available !important;
}


.w-98 {
    width: 98% !important;
}

/* burger image */
.bg-image2,
.bg-image3,
.bg-image4 {
    background-image: url(https://www.middlebycelfrost.com/allapi/assets/img/exp-15.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 480px;
}

.bg-image2:hover {
    box-shadow: 5px 10px 5px 5px rgba(var(--rgb-black), .25);
    transition: all 1s ease-in-out;
}

.bg-image3 {
    background-image: url(https://www.middlebycelfrost.com/allapi/assets/img/exp-16.jpg) !important;
}

.bg-image3:hover {
    box-shadow: -12px 0px 3px 0px rgba(var(--rgb-black), .25);
    transition: all 1s ease-in-out;
}

.bg-image4 {
    background-image: url(https://www.middlebycelfrost.com/allapi/assets/img/exp-19.jpg) !important;
}

.bg-image4:hover {
    box-shadow: 10px 2px 10px 5px rgba(var(--rgb-black), .1);
    margin: 6px;
    transition: all 1s !important;
}

.burgerimage {
    position: relative;
    top: 50px;
    right: 0;
    z-index: 1;
    width: 90%;
    opacity: .8;
    transition: all 1s;
    box-shadow: 0 2px 10px 0 rgba(var(--rgb-black), .25);
    border-radius: 10px;
    /* transform: scale(.95); */
}

.kitchenimage {
    position: relative;
    top: 80px;
    left: 20px;
    z-index: 1;
    width: 95%;
    transition: all 1s;
    opacity: .8;
}

.pizzeriaimage,
.ventlesssolutionimage .automaticon,
.cook {
    position: relative;
    left: 20px;
    width: 80%;
    transition: all 1s ease-in !important;
    border-radius: 10px;
    box-shadow: 0 2px 10px 0 rgba(var(--rgb-black), .15);
}

.cook {
    left: 0 !important;
}

.ventlesssolutionimage {
    position: relative;
    left: -2em !important;
    width: 100%;
}

.planetimage {
    background: linear-gradient(to bottom right, #2fcbcb, #e5ffb3);
}

/* .ventlesssolutionimage:hover,
.automaticon:hover,
.planetimage:hover {
    box-shadow: 10px 2px 10px 5px rgba(var(--rgb-black), .1);
    margin: 6px;
    transition: all 1s !important;
} */



.imagecon:hover .kitchenimage {
    transform: scale(1.1);
    opacity: 1;
    /* Zoom in effect on hover */
}


.aboutimage2 {
    position: relative;
    height: 16em;
}

.image-container {
    overflow: hidden;
    /* Hide the overflow to clip the image */
}

.aboutimage2 {
    transition: transform 0.3s ease;
    /* Add transition for smooth effect */
}

.image-container:hover .aboutimage2 {
    transform: scale(1.1);
    /* Zoom in effect on hover */
}



.imagehover:hover {
    opacity: 1;
    border-radius: 10px;

}


/* card deignECPLORE SOLUTIONS */


.card {
    position: relative;
    overflow: hidden;
    border-radius: 10px;

}

.card-image {
    position: relative;
    /* display: inline-block; */
}

.card-image img {
    width: 100%;
    /* display: block; */
    background-color: rgba(0, 0, 0, 0.5) !important;
    transition: all 3s;
    height: 300px;


}

/* background-color: rgba(0, 0, 0, 0.5); */
/* .card-image::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all opacity 3s;
} */

.box-shadow {
    box-shadow: 0 2px 10px 0 rgba(var(--rgb-black), .25);
}

.rounded-0 {
    border-radius: 0 !important;
}

.rounded {
    border-radius: 5px;
}

.rounded-1 {
    border-radius: 10px;
}

.rounded-pill {
    border-radius: 50%;
}

.card-image:hover::after {
    opacity: 1;
    /* Show overlay on hover */
}

.card-content {
    position: absolute;

    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.card-content h1 {
    margin: 0;
    font-size: 24px;
}

.card-content p {
    margin: 10px 0;
}

.card-content .button {
    display: none;
}

.card:hover .card-content {
    opacity: 1;
    transition: all 10s;
}

.card:hover .card-content h1,
.card:hover .card-content p,
.card:hover .card-content .button {
    display: block;

}

.bg-sliderservices {
    position: relative;
    /* Ensure positioning context for the overlay */
    /* bottom: 150px; */
    z-index: -1;
    background-image: url("https://www.middlebycelfrost.com/allapi/assets/img/bg-service.jpg");
    background-size: cover;
    /* Ensure the image covers the entire element */
}

.bg-sliderservices::after {
    content: "";
    /* Create a pseudo-element for the overlay */
    position: absolute;
    /* Position the overlay relative to its container */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black overlay */
}

.wysiwyg {
    position: relative;
    /* Ensure z-index works */
    z-index: 1;
    /* Ensure the text appears above the overlay */
}

.bg-customercareserviceimg,
.bg-customercareserviceimg2 {
    background-image: url("https://www.middlebycelfrost.com/allapi/assets/img/bg-service.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    /* min-height: 480px; */
}

.bg-customercareserviceimg2 {
    background-image: url("https://www.middlebycelfrost.com/allapi/assets/img/bg-service-2.jpg");

}


.servicepageinput {
    background: #ffffff;
    border: 1px solid #cccccc;
    width: 100%;
    height: 60px;
    color: #333;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    padding: 20px;
    /* padding-right: 20px; */
    border-radius: 7px;
    transition: all 500ms ease;
    font-family: 'poppins';
}

.contact-form form input[type="text"],
.contact-form form input[type="email"],
.contact-form form textarea {
    font-family: 'poppins';
}

.servicepageinput1::placeholder,
select {
    color: grey ! important;
}

.servicepageinput1 {
    color: #333 !important;
}

.explore-content {
    position: absolute;
    top: 22%;
    width: 100%;
}

@media (min-width: 1024px) {
    .explore-page .splide__slide img {
        width: 100% !important;
    }
}

@media (max-width: 1024px) {
    .explore-page .splide__slide img {
        max-width: fit-content;
    }
}

/* @media (max-width: 768px) { */
@media (max-width: 992px) {
    .explore-content {
        position: absolute;
        top: 13%;
    }
}

@media (max-width: 576px) {
    .explore-content {
        position: absolute;
        top: 10%;
    }
}

/* .splide__slide img {
    vertical-align: bottom;
    height: 100%;
}
.w-auto{
    width: auto !important;
}
.splide__list {
    height: 100vh;
} */
.bg-red {
    background-color: var(--color-primary) !important;
}

.expDesc {
    font-size: 16px;
}

.expDesc p {
    display: inline;
}

.splide-overlay::before {
    position: absolute;
    content: '';
    background-color: #000000ab;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 2;
}

.text-justify {
    text-align: justify !important;
}

.slide-text {
    font-size: 45px !important;
}

@media (max-width: 576px) {
    .slide-text {
        font-size: 25px !important;
    }

    .slide-para {
        font-size: 15px !important;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .slide-text {
        font-size: 30px !important;
    }
}

.h-500 {
    height: 500px;
}

.h-220 {
    height: 220px;
}

.h-155 {
    height: 155px;
}

.h-100vh {
    height: 100vh;
}

.youtube-absolute i.fa-play-circle {
    font-size: 90px;
    color: white;
}

.youtube-absolute i.fa-play-circle:hover {
    cursor: pointer;
    color: #ca001b;
}

.bs-gutter {
    --bs-gutter-x: 0 !important;
}

.section-bg {
    background-image: url('https://www.middlebycelfrost.com/allapi/assets/img/explore/connected-equipment-filter.png');
}
.fill {
    height: 100%;
    left: 0;
    margin: 0 !important;
    padding: 0 !important;
    position: absolute;
    right: 0;
    top: 0;
}
.bottom, .fill {
    bottom: 0;
}
.section-bg, .section-content {
    width: 100%;
}
.section-bg {
    overflow: hidden;
}
@media (min-width: 768px) {
    .tw-pt-50 {
        padding-top: 52px !important;
    }
}
#section_150354709 {
    padding-top: 0px;
    padding-bottom: 0px;
}
.bg-fill {
    background-position: 50% 50%;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}
.relative {
    position: relative !important;
}
.section-content {
    z-index: 1;
}
@media screen and (min-width: 850px) {
    .gallery-columns-1 .gallery-item, .large-12, .large-columns-1 .flickity-slider>.col, .large-columns-1>.col {
        flex-basis: 100%;
        max-width: 100%;
    }
}
.small-12, .small-columns-1 .flickity-slider>.col, .small-columns-1>.col {
    flex-basis: 100%;
    max-width: 100%;
}
.col, .columns, .gallery-item {
    margin: 0;
    padding: 0 15px 30px;
    position: relative;
    width: 100%;
}
#row-1809592018 > .col > .col-inner {
    padding: 10px 0px 0px 0px;
}

@media screen and (min-width: 850px) {
    .col:first-child .col-inner {
        margin-left: auto;
        margin-right: 0;
    }
}
.col-inner {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    flex: 1 0 auto;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 100%;
}
#text-2014368407 {
    text-align: center;
}

.header-section {
    padding-top: 114px;
    padding-bottom: 114px;
    color: #fff;
}
.header-section h1 {
    font-size: 62px;
    font-weight: 200;
    color: #fff;
    text-shadow: 2px 2px 4px rgb(0 0 0 / 75%);
    margin-bottom: 15px;
}
.header-section h1 strong {
    font-weight: bold;
}
@media screen and (min-width: 850px) {
    .large-2 {
        flex-basis: 16.6666666667%;
        max-width: 16.6666666667%;
    }
}
@media screen and (min-width: 550px) {
    .medium-2 {
        flex-basis: 16.6666666667%;
        max-width: 16.6666666667%;
    }
}
.small-12, .small-columns-1 .flickity-slider>.col, .small-columns-1>.col {
    flex-basis: 100%;
    max-width: 100%;
}
.col, .columns, .gallery-item {
    margin: 0;
    padding: 0 15px 30px;
    position: relative;
    width: 100%;
}
.has-format, .image-cover, .video-fit {
    background-position: 50% 50%;
    background-size: cover;
    height: auto;
    overflow: hidden;
    position: relative;
}
.text-muted {
    color: #595959;
}
#section_1310336056 {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #999999;
}
#text-1171179086 {
    text-align: center;
    color: rgb(255, 255, 255);
}
#row-1686134166 > .col > .col-inner {
    padding: 0px 60px 0px 60px;
}
@media screen and (min-width: 850px) {
    .col:first-child .col-inner {
        margin-left: auto;
        margin-right: 0;
    }
}
.col-inner {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    flex: 1 0 auto;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 100%;
}
.row-small>.col, .row-small>.flickity-viewport>.flickity-slider>.col {
    margin-bottom: 0;
    padding: 0 9.8px 19.6px;
}

@media screen and (min-width: 850px) {
    .large-6 {
        flex-basis: 50%;
        max-width: 50%;
    }
}
@media screen and (min-width: 550px) {
    .medium-6 {
        flex-basis: 50%;
        max-width: 50%;
    }
}
.small-12, .small-columns-1 .flickity-slider>.col, .small-columns-1>.col {
    flex-basis: 100%;
    max-width: 100%;
}
.col, .columns, .gallery-item {
    margin: 0;
    padding: 0 15px 30px;
    position: relative;
    width: 100%;
}
.row.row-small {
    max-width: 1132.5px;
}
.container-width, .full-width .ubermenu-nav, .container {
    max-width: 1140px;
}
.row-full-width {
    max-width: 100% !important;
}
.overflow-hidden {
    overflow: hidden !important;
}
.col-inner {
    padding: 0px 60px 0px 60px;
}
@media screen and (min-width: 850px) {
    .col:first-child .col-inner {
        margin-left: auto;
        margin-right: 0;
    }
}
.col-inner {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    flex: 1 0 auto;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 100%;
}
#section_469452437 {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: rgb(85, 85, 85);
}
@media screen and (min-width: 850px) {
    #col-283146564 {
        border-left: 1px solid #ececec;
    }
}
.header-section h1 {
    font-size: 62px;
    font-weight: 200;
    color: #fff;
    text-shadow: 2px 2px 4px rgb(0 0 0 / 75%);
    margin-bottom: 15px;
}
.header-section h4 {
    font-size: 28px;
    font-weight: 300;
    color: #fff;
    text-shadow: 2px 2px 4px rgb(0 0 0 / 75%) !important;
}
.pdf-para1 {
    font-size: 20px;
}
.pdf-para2 {
    font-size: 16px;
}

.s0 {
    font-size: 16px;
    font-family: Calibri_58;
    color: #000;
}

.s1 {
    font-size: 24px;
    font-family: PalatinoLinotype-Bold_5f;
    color: #000;
}

.s2 {
    font-size: 18px;
    font-family: Cambria_5a;
    color: #000;
}

.s3 {
    font-size: 24px;
    font-family: Cambria_5a;
    color: #6E2E9F;
}

.s4 {
    font-size: 18px;
    font-family: Cambria_5a;
    color: #2A2A2B;
}

.s5 {
    font-size: 27px;
    font-family: Cambria_5a;
    color: #2A2A2B;
}

.t.v0 {
    transform: scaleX(1.084);
}

.t.v1 {
    transform: scaleX(1.033);
}

.t.v2 {
    transform: scaleX(1.038);
}

.t.v3 {
    transform: scaleX(0.864);
}

.t.v4 {
    transform: scaleX(1.135);
}
.height-auto {
    height: auto !important;
}